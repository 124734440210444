import React from 'react';
import { WorkstationStatus } from 'interfaces/api';
import { useApi, useApiCall } from 'providers';
import { groupBy, keys, map } from 'lodash';
import { Icon, Select, SelectProps } from 'components';
import { faDesktop, faDesktopSlash } from '@fortawesome/pro-regular-svg-icons';
import { Color } from 'interfaces';
import messages from 'messages';

type Props = {
  aid: number;
};

export const WorkstationSelect = (props: Props & SelectProps<any>) => {

  const { workstations: { listWorkstations } } = useApi();

  const { aid, ...rest } = props;

  const loadWorkstations = useApiCall(() => listWorkstations({ aid: props.aid }));

  const groupedWorkstations = loadWorkstations.data ? groupBy(loadWorkstations.data, 'status') : undefined;

  return (
    <Select
      {...rest}
      popupMatchSelectWidth={false}
      loading={loadWorkstations.loading}
      optionGroup={
        groupedWorkstations
          ? map(keys(groupedWorkstations).sort().reverse(), workstationStatus => ({
            // @ts-expect-error todo
            label: messages.orders.workstations.status[workstationStatus],
            options: groupedWorkstations[workstationStatus].map(workstation => ({
              value: workstation.id,
              children: <><Icon icon={workstation.status === WorkstationStatus.Disconnected ? faDesktopSlash : faDesktop} color={Color.Gray}/> {workstation.name}</>,
            })),
          }))
          : undefined
      }
    />
  );

};
