import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AggregatedDiagnosisProperties,
  Feature,
  OrderChangeAction,
  OrderFrontendLogEntry,
  OrderPatientMetaProperties,
  OrderPatientType,
  OrderStatus,
  OrderSwitzerlandProperties,
  OrderWizardLocalization,
  PatientGDT,
  Product,
  UserType,
  WriteableOrderProperties,
} from 'interfaces/api';
import constate from 'constate';
import { MetaLogMessage, useApi, useIntl } from 'providers';
import { useOfficeDoctorContext } from 'modules/orders/providers';
import { ParametersContext, useParameters, useReports } from 'modules/orders/containers/OrderWizard/providers';
import { getWriteableOrder, mapCostUnit, useSwitzerlandBags, useSwitzerlandBagsPromise } from 'modules/orders/utils';
import { useAuthUser } from 'modules/auth/providers';
import { cloneDeep, filter, find, isEqual, keys, omit, pick } from 'lodash';
import { useLogger } from 'providers/LoggerProvider/LoggerProvider';
import { usePrevious } from 'react-use';
import { useGuard } from 'containers';
import dayjs from 'dayjs';
import { diff } from 'deep-object-diff';
import messages from 'messages';
import { useCountrySelectPromise } from 'components/Form/CountrySelect/useCountrySelect.tsx';
import { useWarningModal } from 'components';

export type GlobalOrderSettings = Pick<WriteableOrderProperties, 'scheduled_at' | 'apid' | 'clientname' | 'printerAliasName' | 'workstationChecked'>;

export type OrdersInitialValue = {
  updated_at?: string;
  apid?: number;
  apgid?: number;
  apgids?: number[];
  patient?: OrderPatientMetaProperties;
  switzerland?: OrderSwitzerlandProperties;
  newCase?: string;
  room?: string;
  bed?: string;
  profile?: string;
  insuranceName?: string;
  exttnr?: string;
  clientname?: string;
  printerAliasName?: string;
  logs?: OrderFrontendLogEntry[];
  aisIdentifier?: string;
};

const useOrders = (initialValue: OrdersInitialValue) => {

  const { wizardSettings, officeDoctor } = useOfficeDoctorContext();

  const logger = useLogger('OrdersProvider');

  const params = useParameters();
  const reports = useReports();

  const user = useAuthUser();
  const guard = useGuard();

  const isPoolMode = params.params.pool;

  const initialInvoiceTo = officeDoctor?.localisation === OrderWizardLocalization.CHE
    ? wizardSettings?.preferences?.orderWizardDefaultReportCopySetting
    : initialValue?.patient?.invoiceTo || null;

  const initialOrder: WriteableOrderProperties = useMemo(() => ({
    exttnr: initialValue.exttnr,
    patient: {
      ...(initialValue.patient || {}),
      invoiceTo: initialInvoiceTo,
    },
    switzerland: initialValue.switzerland || {},
    requirements: [],
    selectedDiagnoses: {},
    insuranceName: initialValue.insuranceName,
    printerAliasName: initialValue.printerAliasName,
    aisIdentifier: initialValue.aisIdentifier,
  }), [initialInvoiceTo]);

  const [orders, internalSetOrders] = useState<WriteableOrderProperties[]>([initialOrder]);
  const [logs, setLogs] = useState<OrderFrontendLogEntry[]>(initialValue.logs || []);
  const [untouched, setUntouched] = useState<WriteableOrderProperties[]>([initialOrder]);
  const [globalSettings, setGlobalSettings] = useState<GlobalOrderSettings>({});
  const [immutableRequirementIds, setImmutableRequirementIds] = useState<number[]>([]);
  const [showPatientSelectModal, setShowPatientSelectModal] = useState(false);
  const [initialProfileName, setInitialProfileName] = useState(initialValue.profile);

  // add log
  const appendLog = useCallback((action: OrderChangeAction, data?: any, info?: string) => {
    logger.debug(action, new MetaLogMessage('data', data));
    guard({ feature: Feature.OrderChangeLog }, () => setLogs([...(logs || []), { action, data, info, created_at: dayjs().toISOString() }]));
  }, [logger, globalSettings]);

  const writeableOrders = useMemo(() => orders.map(order => getWriteableOrder({
    ...order,
    ...globalSettings,
    aid: officeDoctor?.aid,
    lanr: officeDoctor?.lanr,
    bsnr: officeDoctor?.bsnr,
    selectedDoctor: officeDoctor?.displayName,
  })), [orders, globalSettings, officeDoctor]);

  const [selectedOrderIndex, selectOrderAtIndex] = useState<number>(undefined);

  const currentOrder = useMemo(() => orders.length === 1 || selectedOrderIndex !== undefined ? orders[selectedOrderIndex || 0] : undefined, [orders, selectedOrderIndex]);
  const readonly = useMemo(() => [OrderStatus.Amendment, OrderStatus.AmendmentQueued, OrderStatus.AmendmentApproved].includes(currentOrder?.status), [currentOrder?.status]);

  const [latestSelectedFormId, setLatestSelectedFormId] = useState<number>(undefined);

  const validPatientsCount = useMemo(() => filter(orders, o => (
    o.patient.firstName?.length > 0
    && o.patient.lastName?.length > 0
  )).length, [orders]);

  const getGroupForms = useCallback(() => wizardSettings?.groupForms?.filter((f) => {
    return !wizardSettings?.preferences?.orderWizardShowCostUnitSelect || currentOrder?.costUnit;
  }), [wizardSettings, currentOrder?.costUnit]);

  const previousCostUnit = usePrevious(currentOrder?.costUnit);

  const setStartGroupForm = () => {
    if (currentOrder?.costUnit !== previousCostUnit && !isPoolMode) {
      const groupForms = getGroupForms();
      const startGroupForm = wizardSettings?.preferences?.orderWizardDefaultGroupForm[currentOrder?.costUnit || 'none'];
      if (groupForms?.map(g => g.id).includes(startGroupForm)) {
        params.setGroupForm(startGroupForm);
        logger.debug('set start group form by default settings', startGroupForm);
      } else {
        if (params.params.groupFormId) {
          const currentGroupForm = find(groupForms, { id: params.params.groupFormId });
          const sameNameGroupForm = find(groupForms, g => g.name === currentGroupForm?.name && g.costUnit === currentOrder?.costUnit);
          logger.debug('try to set start group form by same name', currentGroupForm, sameNameGroupForm);

          if (sameNameGroupForm) {
            params.setGroupForm(sameNameGroupForm.id);
            return;
          }
        }

        logger.debug('reset start group form');
        params.setGroupBy(undefined);

      }
    }
  };

  useEffect(setStartGroupForm, [currentOrder?.costUnit, getGroupForms, wizardSettings?.preferences?.orderWizardStartGroupForm]);

  const { orderWizardShowWorkstationSelect, orderWizardRememberLastWorkstation, orderWizardLastWorkstationId } = wizardSettings?.preferences || {};

  useEffect(() => {
    if (!globalSettings?.apid && orderWizardShowWorkstationSelect && orderWizardRememberLastWorkstation && orderWizardLastWorkstationId > 0) {
      setGlobalSettings({ ...globalSettings, apid: orderWizardLastWorkstationId });
      appendLog(OrderChangeAction.SetLastUsedWorkstation, { apid: orderWizardLastWorkstationId });
    }
  }, [globalSettings?.apid, orderWizardShowWorkstationSelect, orderWizardRememberLastWorkstation, orderWizardLastWorkstationId]);

  const { orderWizardAustriaSettings: { getAustriaHvCode } } = useApi();

  const setSingleCostUnit = () => {
    // set cost unit if only one is available
    if (wizardSettings?.costUnits?.length === 1 && currentOrder && currentOrder.costUnit === undefined) {
      setOrderProperties({ costUnit: wizardSettings?.costUnits[0] });
    }
  };

  useEffect(setSingleCostUnit, [wizardSettings?.costUnits]);

  const setCostUnitFromPatientHvCode = () => {
    // set cost unit from patient hv code
    const patientHvCode = currentOrder?.patient?.hvcode;
    if (patientHvCode && officeDoctor?.localisation === OrderWizardLocalization.AUT && wizardSettings?.costUnits) {
      getAustriaHvCode({ code: patientHvCode }).then(({ costUnit }) => {
        setOrderProperties({ costUnit });
        appendLog(OrderChangeAction.SetCostUnitFromPatientHvCode, { costUnit });
      });
    }
  };

  useEffect(setCostUnitFromPatientHvCode, [currentOrder?.patient?.hvcode, officeDoctor?.localisation, wizardSettings?.costUnits]);

  const [showCostUnitSelect, setShowCostUnitSelect] = useState(false);

  const setCostUnitFromPatient = () => {
    if (officeDoctor?.localisation && officeDoctor?.localisation !== OrderWizardLocalization.AUT) {
      // set cost unit from patient
      if (currentOrder && currentOrder.costUnit === undefined) {
        const patientCostUnit = currentOrder.patient?.costUnit;
        if (patientCostUnit?.length > 0) {
          const newCostUnit = mapCostUnit(patientCostUnit);
          if (wizardSettings?.costUnits.includes(newCostUnit)) {
            setOrderProperties({ costUnit: newCostUnit });
            appendLog(OrderChangeAction.SetCostUnitFromPatient, { costUnit: newCostUnit });
            return;
          }
        }
      }
    }
  };

  useEffect(setCostUnitFromPatient, [wizardSettings?.costUnits, currentOrder?.patient, officeDoctor?.localisation]);

  const { orders: { cancelEditOrders } } = useApi();
  const handleTabClosing = () => {
    const editingOrders = orders.filter(o => o.status === OrderStatus.Processing);
    if (editingOrders.length > 0) {
      cancelEditOrders({ poolIds: editingOrders.map(o => o.poolId) });
    }
  };

  useEffect(() => {
    window.addEventListener('unload', handleTabClosing);
    return () => {
      window.removeEventListener('unload', handleTabClosing);
    };
  });

  // reset selected order if last was deleted
  useEffect(() => {
    if (selectedOrderIndex > orders?.length - 1) {
      selectOrderAtIndex(undefined);
    }
  }, [selectedOrderIndex, orders]);

  // set insurance name from patient
  const setInsuranceNameFromPatient = () => {
    if (!currentOrder?.insuranceName && currentOrder?.patient?.insuranceName) {
      const insuranceName = currentOrder?.patient?.insuranceName;
      setOrderProperties({ insuranceName });
      appendLog(OrderChangeAction.SetInsuranceNameFromPatient, { insuranceName });
    }
  };

  // set pool parameter
  useEffect(() => {
    params.setPool(orders.length > 1);
  }, [orders.length]);

  useEffect(setInsuranceNameFromPatient, [currentOrder?.patient?.insuranceName]);

  const checkCostUnit = useCallback((costUnit: string) => {
    const newCostUnit = mapCostUnit(costUnit);
    return wizardSettings?.costUnits.includes(newCostUnit) ? newCostUnit : undefined;
  }, [wizardSettings?.costUnits]);

  const applyOrderDefaultValues = useCallback((order: WriteableOrderProperties) => ({
    ...order,
    ...globalSettings,
    aid: officeDoctor?.aid,
    lanr: officeDoctor?.lanr,
    bsnr: officeDoctor?.bsnr,
    selectedDoctor: officeDoctor?.displayName,
    externalId2: officeDoctor?.externalId2,
    costUnit: checkCostUnit(mapCostUnit(order?.costUnit || order?.patient?.costUnit) || find(wizardSettings?.forms, { id: order.requirements?.[0]?.formId })?.costUnit),
    requirements: order.requirements || [],
    patient: { ...(order.patient || {}), invoiceTo: order.patient?.invoiceTo || initialInvoiceTo },
    insuranceName: order?.insuranceName || order.patient?.insuranceName,
    selectedDiagnoses: order.selectedDiagnoses || {},
  }), [globalSettings, officeDoctor, wizardSettings, checkCostUnit]);

  /**
   * Set orders default values
   */
  const setOrdersDefaultValues = useCallback(() => {
    internalSetOrders(prevOrders => prevOrders.map(o => applyOrderDefaultValues(o)));
  }, [logger, internalSetOrders, applyOrderDefaultValues]);

  /**
   * Reset orders
   * @param newOrders
   */
  const resetOrders = (newOrders?: WriteableOrderProperties[]) => {
    selectOrderAtIndex(undefined);
    setGlobalSettings({});

    logger.debug('Resetting orders to ' + (newOrders ? 'new orders' : 'initialOrder'), newOrders || [initialOrder]);

    newOrders = cloneDeep(newOrders || [initialOrder]);

    internalSetOrders(newOrders.map(o => ({
      ...applyOrderDefaultValues(o),
      scheduled_at: o.scheduled_at,
      apid: o.apid,
    })));

    setLogs([]);
    setUntouched(cloneDeep(newOrders));
    setInitialProfileName(undefined);
    setGlobalSettings(pick(newOrders[0], ['scheduled_at', 'infectious', 'urgent', 'costUnit', 'apid', 'insuranceName', 'clientname', 'printerAliasName']));
    setLatestSelectedFormId(newOrders?.[0]?.requirements?.[0]?.formId);
    params.reset();
    params.setPool(newOrders.length > 1);
  };

  /**
   * Set orders
   * @param callback
   */
  const setOrder = useCallback((cb: (prevOrder: WriteableOrderProperties) => WriteableOrderProperties, atIndex?: number) => {
    internalSetOrders(prevOrders => prevOrders.map((o, oIdx) => {
      const index = atIndex === undefined ? selectedOrderIndex || 0 : atIndex;
      return oIdx === index ? { ...o, ...applyOrderDefaultValues(cb(o)) } : o;
    }));
  }, [internalSetOrders, applyOrderDefaultValues, selectedOrderIndex]);

  /**
   * Set all orders or selected order
   * @param callback
   */
  const setAllOrSelectedOrders = useCallback((cb: (prevOrder: WriteableOrderProperties) => WriteableOrderProperties) => {
    internalSetOrders(prevOrders => prevOrders.map((o, oIdx) => {
      return prevOrders.length > 1 && selectedOrderIndex !== undefined && selectedOrderIndex !== oIdx ? o : { ...o, ...applyOrderDefaultValues(cb(o)) };
    }));
  }, [internalSetOrders, applyOrderDefaultValues, selectedOrderIndex]);

  /**
   * Set orders
   * @param callback
   */
  const setOrders = useCallback((cb: (prevOrders?: WriteableOrderProperties[]) => WriteableOrderProperties[]) => {
    internalSetOrders(prevOrders => cb(prevOrders).map(applyOrderDefaultValues));
  }, [internalSetOrders, applyOrderDefaultValues]);

  /**
   * Set orders with map callback
   * @param callback
   */
  const setOrdersMapped = useCallback((cb: (order: WriteableOrderProperties, idx?: number, prevOrders?: WriteableOrderProperties[]) => Partial<WriteableOrderProperties>) => {
    internalSetOrders(prevOrders => prevOrders.map((o, idx) => applyOrderDefaultValues(cb(o, idx, prevOrders))));
  }, [internalSetOrders, applyOrderDefaultValues]);

  /**
   * Set order properties at index
   * @param properties
   * @param atIndex
   */
  const setOrderProperties = useCallback((properties: Partial<WriteableOrderProperties>, atIndex?: number) => {
    internalSetOrders(prevOrders => prevOrders.map((o, oIdx) => {
      o = applyOrderDefaultValues(o);
      const index = atIndex === undefined ? selectedOrderIndex || 0 : atIndex;
      return oIdx === index ? { ...o, ...properties } : o;
    }));
  }, [internalSetOrders, applyOrderDefaultValues, selectedOrderIndex]);

  /**
   * Set properties for all orders
   * @param properties
   */
  const setAllOrdersProperties = useCallback((properties: Partial<WriteableOrderProperties>) => {
    internalSetOrders(prevOrders => prevOrders.map(o => ({
      ...applyOrderDefaultValues(o),
      ...properties,
    })));
  }, [internalSetOrders, applyOrderDefaultValues]);

  /**
   * Remove order at index
   */
  const removeOrderAtIndex = useCallback((index: number) => {
    internalSetOrders(prevOrders => prevOrders.filter((o, idx) => idx !== index));
  }, [internalSetOrders]);

  /**
   * Toggle diagnosis
   */
  const toggleDiagnosis = useCallback((diagnosis: AggregatedDiagnosisProperties) => {
    setOrder(prevOrder => ({
      ...prevOrder,
      selectedDiagnoses: prevOrder.selectedDiagnoses[diagnosis.id]
        ? omit(prevOrder.selectedDiagnoses, diagnosis.id)
        : { ...prevOrder.selectedDiagnoses, [diagnosis.id]: diagnosis.key }
      ,
    }));
  }, [setOrder, currentOrder]);

  useEffect(() => {
    setOrdersDefaultValues();
  }, [wizardSettings]);

  /**
   * Global settings changed
   */
  const previousGlobalSettings = usePrevious(globalSettings);
  const diffGlobalSettings = diff(previousGlobalSettings, globalSettings);
  useEffect(() => {
    if (keys(diffGlobalSettings).length > 0) {
      appendLog(OrderChangeAction.GlobalSettingsChanged, diffGlobalSettings);
      logger.debug('Global Settings', globalSettings);
      setOrdersDefaultValues();
    }
  }, [diffGlobalSettings]);

  /**
   * Log cost unit change
   */
  useEffect(() => {
    if (currentOrder?.costUnit) {
      appendLog(OrderChangeAction.CostUnitChanged, { costUnit: currentOrder?.costUnit });
    }
  }, [currentOrder?.costUnit]);

  /**
   * Office Doctor changed
   */
  const previousOfficeDoctor = usePrevious(officeDoctor);
  const diffOfficeDoctor = diff(previousOfficeDoctor, officeDoctor);
  useEffect(() => {
    if (keys(diffOfficeDoctor).length > 0) {
      appendLog(OrderChangeAction.OfficeDoctorChanged, diffOfficeDoctor);
      setOrdersDefaultValues();
    }
  }, [diffOfficeDoctor]);

  /**
   * Parameters changed
   */
  const previousParameters = usePrevious(params.params);
  const diffParameters: ParametersContext = diff(previousParameters, params.params);
  useEffect(() => {
    if (keys(diffParameters).length > 0) {
      if (diffParameters.groupFormId) {
        appendLog(OrderChangeAction.GroupForm, find(getGroupForms(), { id: params.params.groupFormId }));
      } else if (diffParameters.groupBy !== undefined) {
        appendLog(OrderChangeAction.GroupBy, { name: messages.orders.requirementAggregations.types[diffParameters.groupBy].defaultMessage });
      } else {
        appendLog(OrderChangeAction.ParametersChanged, diffParameters);
      }
    }
  }, [diffParameters]);

  /**
   * Orders changed
   */
  const omitOrderProps = (orders: WriteableOrderProperties[]) => orders?.map(o => omit(o, ['requirements']));
  const previousOrders = usePrevious(orders);
  const diffOrders = diff(omitOrderProps(previousOrders), omitOrderProps(orders));
  useEffect(() => {
    if (keys(diffOrders).length > 0) {
      logger.debug('Orders', orders);
      appendLog(OrderChangeAction.OrderChanged, diffOrders);
    }
  }, [diffOrders]);

  const isTouched = () => !isEqual(orders, untouched);

  const patient = currentOrder?.patient;

  const {
    gdtPatient: { getPatientReports, readGdtPatient, readGdtPatientFromTimestamp },
    orders: { getOrderByCaseNumber },
    workstations: { getWorkstationByAlias },
  } = useApi();

  const loadReports = () => {
    if (selectedOrderIndex !== undefined || orders.length === 1 && guard({ product: Product.OB }, () => true)) {
      const patient = orders[selectedOrderIndex || 0]?.patient;
      if (patient?.pid > 0) {
        getPatientReports({ id: patient.pid, type: OrderPatientType.LIS }).then(reports.setReports).catch(() => reports.reset());
      } else if (patient.apgid > 0) {
        getPatientReports({ id: patient.apgid, type: OrderPatientType.AIS }).then(reports.setReports).catch(() => reports.reset());
      } else {
        reports.reset();
      }
    } else {
      reports.reset();
    }
  };

  useEffect(loadReports, [selectedOrderIndex, patient?.apgid, patient?.pid]);

  const { mapSwitzerlandInsuranceValues } = useSwitzerlandBags();
  const countrySelectEnabled = guard({ feature: Feature.CountryCodeSelect }, () => true);

  const mapGdtPatientProperties = async (data: PatientGDT) => {
    if (countrySelectEnabled) {
      const countries: any = await useCountrySelectPromise;
      data.country = keys(countries).filter(c => countries[c] === data.country)[0] || data.country;
    }

    return mapSwitzerlandInsuranceValues(data, await useSwitzerlandBagsPromise);

  };

  const warningModal = useWarningModal();
  const { translate } = useIntl();

  const throwGdtError = () => {
    warningModal({
      title: translate(messages.orders.gdtError.title),
      content: translate(messages.orders.gdtError.content),
    });
  };

  const loadGdtPatient = () => {
    readGdtPatient({ aid: user.entityId, apgid: initialValue.apgid })
      .then(mapGdtPatientProperties)
      .then((patient) => {
        logger.debug('Loaded gdt patient', new MetaLogMessage('patient', patient));
        setOrderProperties({ patient, dz116b: patient.dz116b });
        appendLog(OrderChangeAction.LoadedGdtPatient, patient);
      }).catch(throwGdtError);
  };

  const loadGdtPatients = () => {
    Promise.all(initialValue?.apgids.map(apgid => readGdtPatient({ apgid, aid: user.entityId })))
      .then(response => Promise.all(filter(response).map(mapGdtPatientProperties)))
      .then((response) => {
        if (response.length > 0) {
          logger.debug('Loaded gdt patients by apgids', response);
          appendLog(OrderChangeAction.LoadedGdtPatients, response);
          setOrders(prevOrders => filter(response)
            .map((patient, idx) => ({ ...(prevOrders[idx] || {}), patient: { ...(prevOrders[idx]?.patient || {}), ...patient }, dz116b: patient.dz116b })));
        }
      }).catch(throwGdtError);
  };

  const loadGdtPatientByTimestamp = () => {
    readGdtPatientFromTimestamp({ aid: user.entityId, updated_at: initialValue.updated_at })
      .then(mapGdtPatientProperties)
      .then((patient) => {
        logger.debug('Loaded gdt patient by timestamp', patient);
        setOrderProperties({ patient, dz116b: patient.dz116b });
        appendLog(OrderChangeAction.LoadedGdtPatientByTimestamp, patient);
      }).catch(throwGdtError);
  };

  const loadedPatientByCaseNumber = () => {
    getOrderByCaseNumber({ caseNumber: initialValue.newCase }).then((response) => {

      logger.debug('Loaded patient by case number', response);

      if (initialValue.room) {
        response.patient.hospitalRoomNumber = initialValue.room;
      }

      if (initialValue.bed) {
        response.patient.hospitalBedNumber = initialValue.bed;
      }

      const setProps = {
        hospitalStation: response.hospitalStation,
        patient: { ...response.patient, costUnit: response.costUnit },
        switzerland: response.switzerland,
        hungary: response.hungary,
        dz116b: response.dz116b,
      };

      appendLog(OrderChangeAction.LoadedPatientByCaseNumber, { caseNumber: initialValue.newCase, ...setProps });
      setOrderProperties(setProps);
    });
  };

  const loadWorkstationByAlias = () => {
    getWorkstationByAlias({ alias: initialValue?.clientname }).then((workstation) => {
      setGlobalSettings({ ...globalSettings, apid: workstation.id, clientname: undefined, workstationChecked: true });
    });
  };

  useEffect(() => {

    if (initialValue?.apid) {
      logger.debug('Set apid from initialValue', initialValue?.apid);
      setGlobalSettings({ ...globalSettings, apid: initialValue.apid, workstationChecked: true });
    } else if (initialValue?.clientname) {
      logger.debug('Set apid from clientname', initialValue?.clientname);
      loadWorkstationByAlias();
    }

    if (!!user?.entityId && user?.type === UserType.ARZ) {
      if (initialValue?.apgid) {
        loadGdtPatient();
      } else if (initialValue?.apgids?.length > 0) {
        loadGdtPatients();
      } else if (initialValue?.updated_at) {
        loadGdtPatientByTimestamp();
      } else if (initialValue?.newCase) {
        loadedPatientByCaseNumber();
      }
    }

  }, []);

  return {
    orders,
    params,
    ...reports,
    initialOrder,
    setOrder,
    setOrders,
    setOrdersMapped,
    setAllOrSelectedOrders,
    setOrderProperties,
    setAllOrdersProperties,
    applyOrderDefaultValues,
    resetOrders,
    currentOrder,
    selectedOrderIndex,
    selectOrderAtIndex,
    removeOrderAtIndex,
    validPatientsCount,
    globalSettings,
    setGlobalSettings,
    immutableRequirementIds,
    setImmutableRequirementIds,
    latestSelectedFormId,
    setLatestSelectedFormId,
    isTouched,
    writeableOrders,
    showPatientSelectModal,
    setShowPatientSelectModal,
    initialProfileName,
    setInitialProfileName,
    toggleDiagnosis,
    readonly,
    showCostUnitSelect,
    setShowCostUnitSelect,
    getGroupForms,
    appendLog,
    logs,
    isPoolMode,
  };

};

const [OrdersProvider, useOrdersContext] = constate(useOrders);
export { OrdersProvider, useOrdersContext };
